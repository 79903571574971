// import React from "react";
import { Link } from "gatsby";
import { Button } from "../../../../styles/components/buttons";
import { Title } from "../../../../styles/components/titles";
import { Wrapper, SubTitle, Description } from "./style";

const SectionText = () => {
  return (
    <Wrapper className="container section-text">
      <div>
        <SubTitle>Depuis 12 ans à votre service</SubTitle>
        <Title>Découvrez des prestations de qualité</Title>
      </div>
      <Description>
        <div>
          <p>
            Depuis 15 ans vous faites confiance à Energie Piscines pour realiser
            vos travaux. Lorem ipsum dolor, sit amet consectetur adipisicing
            elit.
          </p>
          <p>
            Beatae soluta iste ratione quis eos culpa, fugit eveniet aliquam
            itaque! Corporis ex dicta quo dignissimos fugiat provident optio
            accusamus voluptatem vel.
          </p>
        </div>
        <div>
          <Button>Je prends contact</Button>
        </div>
      </Description>
    </Wrapper>
  );
};

export default SectionText;
