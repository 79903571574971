import styled from "@emotion/styled";

export const Wrapper = styled.section`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  .gatsby-image-wrapper {
    max-height: calc(100vh - 176px);
    ${({ theme }) => theme.breakpoints.tabletPortrait} {
      min-height: 520px;
      max-height: auto;
    }
  }

  &:before {
    content: "";
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: hsl(214deg 8% 14% / 25%);
  }
`;

export const WrapperTitle = styled(`div`)``;

export const WrapperAbsolute = styled(`div`)`
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 10;
`;

export const Title = styled(`h1`)`
  color: #fff;
  font-size: clamp(25px, 4vw, 55px);
  line-height: 1.25;
  font-weight: 700;
  text-shadow: 3px 3px hsl(191deg 63% 1% / 34%);
`;

export const Description = styled(`p`)`
  max-width: 940px;
  margin-top: 16px;
  font-size: clamp(16px, 2vw, 22px);
  text-shadow: 1px 1px hsl(214deg 59% 25%);
  line-height: 1.15;
  color: #fff;
  font-weight: 300;

  a {
    color: #fff;
  }
`;
