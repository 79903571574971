import SectionText from "../components/SectionText"
import { IGatsbyImageData } from "gatsby-plugin-image"
import Banner from "./Banner/Banner"
import { HomeService } from "../interfaces/HomeService"
import SectionServices from "./SectionServices"
import SectionForm from "../../../components/SectionForm"
import GoogleMap from "../../../components/GoogleMap"


type Props = {
	mainImage: IGatsbyImageData | undefined
	services: HomeService[]
}

const TemplateHome = ({ mainImage, services }: Props) => {
	return (
		<div className="home">
			<Banner mainImage={mainImage} />
			<SectionServices services={services} />
			<SectionText />
			<GoogleMap />
			<SectionForm />
		</div>
	)
}

export default TemplateHome