import styled from "@emotion/styled";

export const Wrapper = styled.div`
  position: relative;
  width: 33%;
  padding: 36px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #fff;
  border-radius: 4px;

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    width: calc(50% - 4px);
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    width: 100%;
  }

  .link {
    display: block;
  }
  .title {
    margin-top: 16px;
  }
  .description {
    margin-top: 8px;
    font-size: 16px;
    line-height: 1.2;

    p {
      margin-bottom: 8px;
    }
  }
  .wrapper-button {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
`;
