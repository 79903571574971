import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import LayoutMain from "../components/LayoutMain";
import TemplateHome from "../features/home/components/TemplateHome";
import { HomeService } from "../features/home/interfaces/HomeService";

import getGraphBase from "../helpers/getGraphBase";
import SeoHelmet from "../features/seo/components/SeoHelmet";

const IndexPage = (props: PageProps) => {
  const { data }: any = props;
  if (!data) {
    return <>Erreur no data</>;
  }

  const mainImage: IGatsbyImageData | undefined = getImage(
    data.sanityHome.mainImage.asset
  );
  const logo: IGatsbyImageData | undefined = getImage(
    data.sanitySiteSettings.logo.asset
  );

  const sectionServices: HomeService[] = data.sanityHome.sectionServices;
  const schemaHome = getGraphBase();

  return (
    <>
      <SeoHelmet
        seoTitle="Energie Piscine mon pisciniste dans les
          Alpes-Maritimes."
        seoDescription="Spécialisé dans l’entretien, l’installation, la rénovation, la construction le dépannage. Energie piscines basé à Vence est à votre service."
        canonical="https://energiepiscines.com"
        schema={schemaHome}
      />

      <LayoutMain logo={logo}>
        {mainImage && (
          <TemplateHome mainImage={mainImage} services={sectionServices} />
        )}
      </LayoutMain>
    </>
  );
};

export const query = graphql`
  {
    sanityHome(slug: { current: { eq: "home" } }) {
      title
      sectionServices {
        _key
        title
        _rawResume(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
        vignette {
          alt
          asset {
            gatsbyImageData(
              width: 380
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      mainImage {
        asset {
          gatsbyImageData(
            width: 1920
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    sanitySiteSettings {
      title
      logo {
        asset {
          gatsbyImageData(
            width: 267
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

export default IndexPage;
