import { MiniTitle } from "../../../../styles/components/titles";
import styled from "@emotion/styled";

export const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 35% auto;
  padding: 80px 0;
  gap: 32px;

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    grid-template-columns: 1fr;
    padding: 40px 3vw;
  } ;
`;

export const Title = styled.p`
  font-size: clamp(25px, 4vw, 55px);
  line-height: 1.1;
  font-family: ${({ theme }) => theme.typography.fonts.heading};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary[300]}; ;
`;

export const SubTitle = styled(MiniTitle)`
  position: relative;
  padding-left: 119px;

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    padding-left: 105px;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    padding-left: 79px;
  }

  &:before {
    position: absolute;
    top: 35%;
    left: 0;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: 80px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary[500]};

    ${({ theme }) => theme.breakpoints.mobile} {
      width: 48px;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-size: clamp(16px, 3vw, 22px);
    line-height: 1.15;
    margin-bottom: 8px;
  }
`;
