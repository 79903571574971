import { Link } from "gatsby";
import { Button } from "../../../../styles/components/buttons";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import {
  Title,
  Wrapper,
  WrapperTitle,
  WrapperAbsolute,
  Description,
} from "./style";

type Props = {
  mainImage: IGatsbyImageData | undefined;
};

const Banner = ({ mainImage }: Props) => {
  return (
    <Wrapper>
      <WrapperTitle className="container wrapper-title">
        <WrapperAbsolute>
          <Title className="title">
            Depuis 10 ans votre pisciniste
            <br className="hide-mobile" /> à Vence
          </Title>
          <Description className="description">
            Spécialisée dans l’entretien, l’installation, la rénovation, la
            construction mais aussi le dépannage.
            <br />
            La société Energie piscines, basée à{" "}
            <a href="/entretien-piscine-vence/">Vence</a>, met son savoir-faire
            à votre service. <br />
            Notre secteur d’intervention est vaste. Nous intervenons à
            <Link to="/entretien-de-piscine-a-tourrettes-sur-loup-par-un-professionnel">
              Tourrettes sur Loup
            </Link>
            , Saint-Paul, Cagnes sur mer, Biot,{" "}
            <a href="/la-colle-sur-loup-entretien-piscine">la Colle sur Loup</a>{" "}
            Saint-Jeannet…
          </Description>
          <Button as="a" href="#section-contact">
            Je prends contact
          </Button>
        </WrapperAbsolute>
      </WrapperTitle>
      {mainImage && (
        <GatsbyImage
          loading="eager"
          objectFit="cover"
          image={mainImage}
          alt="Piscine avec vue mer"
        />
      )}
    </Wrapper>
  );
};

export default Banner;
