// import React from "react";
import { Wrapper } from "./style"
import { HomeService } from "../../interfaces/HomeService";
import ServiceItem from "../ServiceItem";

interface Props {
  services: HomeService[]
}

const SectionServices = ({ services }: Props) => {
  return (
    <Wrapper className="section-text">
      <div className="container clear">
        <div className="wrapper-list">
          {services.map(service => <ServiceItem key={service.slug.current} service={service} />)}
        </div>
      </div>
    </Wrapper>
  );
};

export default SectionServices;
