import styled from "@emotion/styled";

export const Wrapper = styled.section`
  position: relative;
  z-index: 30;
  background-color: ${({ theme }) => theme.colors.primary[50]};
  padding-bottom: 80px;

  .wrapper-list {
    margin-top: -80px;
    display: flex;
    gap: 4px;
    float: left;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
