// import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Wrapper } from "./style"
import { HomeService } from "../../interfaces/HomeService";
import PortableText from "../../../../components/Sanity/PortableText";
import { ButtonKnowMore } from "../../../../styles/components/buttons";

interface Props {
  service: HomeService
}

const ServiceItem = ({ service }: Props) => {
  const vignette: IGatsbyImageData | undefined = getImage(service.vignette.asset)

  return (
    <Wrapper className="service-item">
      <div className="wrapper-img-description">
        {vignette &&
          <Link className="link" to={service.slug.current}>
            <GatsbyImage
              loading="eager"
              objectFit="cover"
              image={vignette}
              alt="Piscine avec vue mer" />
          </Link>
        }
        <h2 className="title">{service.title}</h2>
        <div className="description"><PortableText blocks={service._rawResume} /></div>
      </div>
      <div className="wrapper-button">
        <ButtonKnowMore to={service.slug.current}>En savoir plus</ButtonKnowMore>
      </div>
    </Wrapper>
  );
};

export default ServiceItem;
